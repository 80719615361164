import { airportsRoutes } from "./Airports/airport-routes";
import { countriesRoutes } from "./Countries/countries-routes";
import { duasRoutes } from "./Duas/duas-routes";
import { organizationsRoutes } from "./Organizations/organization-routes";
import SuperAdminLayout from "./SuperAdminLayout";
import { usersRoutes } from "./Users/users-routes";

export const superAdminRoutes = [
  {
    path: "superadmin",
    element: SuperAdminLayout,
    children: [
      ...organizationsRoutes,
      ...countriesRoutes,
      ...airportsRoutes,
      ...usersRoutes,
      ...duasRoutes
    ],
  },
];
