import AppTable from 'components/AppTable/AppTable'
import { BookingStatus } from 'components/BookingStatus'
import { toDate } from 'helpers/dateHelper'
import _ from 'lodash'
import React from 'react'

export const TouristList = ({ tourists }) => {
    const touristColumnConfig = [
        {
            label: '#',
            render: (v, index) => index + 1
        },
        {
            key: 'name',
            label: 'Турист',
            render: (i) => _.get(i, 'customer.lastName') + ' ' + _.get(i, 'customer.firstName')
        },
        {
            key: 'createdAt',
            label: 'Дата приобретения',
            formatter: (v) => toDate(v)
        },
        {
            key: 'bookingStatusID',
            label: 'Статус заказа',
            render: (i) => <BookingStatus bookingStatus={i.booking?.bookingStatus}></BookingStatus>
        }
    ]

    if (!tourists) {
        return null
    }

    return (
        <AppTable data={tourists} columnConfig={touristColumnConfig}></AppTable>
    )
}
