import AppForm from "components/AppForm/AppForm";
import CrudPage from "components/CrudPage/CrudPage";
import { mapFieldWithOptions } from "helpers/mapFieldWithOptions";
import { withRemoteDataAndSpinner } from "hoc/withRemoteDataAndSpinner";
import withRouter from "hoc/withRouter";
import { useLoadAll } from "hooks/useLoadAll";
import { rolesAdminService } from "services/admin/rolesService";
import membersService from "services/admin/membersService";
import inputTypeEnum from "enums/inputTypes";
import { phoneCountries } from "services/countryData";
import { snack } from "components/Snack/Snack";
import { phoneToBackendFormat, phoneToUI } from "hajjtravel-utils";

const MemberForm = ({
  router: {
    navigate,
    params: { id },
  },
  data: { member, roles },
}) => {
  /**
   * Скорей всего нужно перевести данные о сотрудника в плоский формат
   */
  const fieldsWithData = mapFieldWithOptions([
    {
      key: "name",
      label: "ФИО(полностью как указан в удостоверение личности)",
      required: true,
      placeholder: 'Введите ФИО пользователя',
      inputType: inputTypeEnum.text,
    },
    {
      key: "user.email",
      label: "Email",
      inputType: inputTypeEnum.email,
    },
    {
      key: "user.phonePrefix",
      label: "Код страны",
      required: true,
      inputType: inputTypeEnum.autocomplete,
      options: phoneCountries.map(p => ({ id: p.prefix, name: `${p.country_name} (+${p.prefix})` })),
      value: 7
    },
    {
      key: "user.phone",
      label: "Телефон",
      required: true,
      inputType: inputTypeEnum.tel,
      formatter: (v, getValues) => {
        if (!v) {
          return null
        }
        console.log("prefix: ", getValues("user.phonePrefix"));
        const prefix = getValues("user.phonePrefix")
        if (prefix) {
          return phoneToUI(v, prefix)
        }

        return v
      }
    },
    {
      key: "roleID",
      label: "Роли",
      inputType: inputTypeEnum.select,
      options: [],
      multiple: false,
      required: true
    },
    {
      key: "canProcessOrder",
      label: "Автоматически назначать менеджером для новых заказов",
      inputType: inputTypeEnum.checkbox,      
    },
  ], {
    roleID: { options: roles },
  });

  console.log("fieldsWithData:", fieldsWithData);


  const onSubmit = async (formData) => {
    try {
      if (formData.has('user.phone')) {
        // Переводим в backend format
        const phonePrefix = formData.get('user.phonePrefix')
        const localPhone = formData.get('user.phone')
        const phone = phoneToBackendFormat(localPhone, phonePrefix)
        formData.set('user.phone', phone)
      }

      formData.set('canProcessOrder', formData.get('canProcessOrder') === 'on')

      if (member) {
        await membersService.put(id, formData)
        snack("Сотрудник обновлен успешно!");
      } else {
        await membersService.post(formData)
        snack("Сотрудник создан успешно!");
      }

      setTimeout(() => {
        navigate(-1);
      }, 1000);
    } catch (ex) {
      console.error(ex)
    }
  };

  return (
    <CrudPage title={member ? 'Редактирование сотрудника' : 'Создание сотрудника'}>
      <AppForm onSubmit={onSubmit} data={member} fields={fieldsWithData}></AppForm>
    </CrudPage>
  );
};
export default withRouter(
  withRemoteDataAndSpinner(MemberForm, (router) => {
    let requests = {
      roles: rolesAdminService.getAll,
    }
    if (router.params.id) {
      requests['member'] = () => membersService.get(router.params.id)
    }
    return useLoadAll(requests);
  })
);
