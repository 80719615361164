import { Outlet } from "react-router-dom";
import { DuaCategoryList } from "./DuaCategoryList";
import DuaCategoryForm from "./DuaCategoryForm";

export const duaCategoryRoutes = [
  {
    path: "dua-categories",
    element: Outlet,
    children: [
      {
        path: "",
        element: DuaCategoryList,
      },
      {
        path: "add",
        element: DuaCategoryForm,
      },
      {
        path: "edit/:id",
        element: DuaCategoryForm,
      },
    ],
  },
];
