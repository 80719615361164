import { Button, Card, CardActions, CardContent } from '@mui/material'
import { toDate } from 'helpers/dateHelper'
import _ from 'lodash';
import { Link } from 'react-router-dom'

export const TourScheduleCard = ({ data, options }) => {
    console.log(`data:`, data);
    const hideButtons = _.get(options, 'hideButtons')

    return (
        <Card>
            <CardContent>
                <div className='flex gap-16'>
                    <div>
                        {data.photo ? <img src={data.photo} style={{ width: 50 }} /> : null}
                    </div>
                    <div>
                        <h3>{data.name}</h3>
                        {data.description && <p dangerouslySetInnerHTML={{ __html: data.description }}></p>}
                        <div>
                            <label>Дата создания: {toDate(data.createdAt)}</label>
                        </div>
                    </div>
                </div>
            </CardContent>
            {hideButtons ? null : <CardActions className='justify-end'>
                <Link to={`view/${data.id}`}>
                    <Button size="small">Детали</Button>
                </Link>
                <Link to={`edit/${data.id}`}>
                    <Button size="small">Редактировать</Button>
                </Link>
            </CardActions>}
        </Card>
    )
}
