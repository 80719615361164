import AppForm from "components/AppForm/AppForm";
import CrudPage from "components/CrudPage/CrudPage";
import { withRemoteDataAndSpinner } from "hoc/withRemoteDataAndSpinner";
import withRouter from "hoc/withRouter";
import { useLoadAll } from "hooks/useLoadAll";
import inputTypeEnum from "enums/inputTypes";
import { snack } from "components/Snack/Snack";
import citiesSAService from "services/superadmin/citiesSAService";
import countriesSAService from "services/superadmin/countriesSAService";

const CityForm = ({
  router: {
    navigate,
    params: { cityID },
  },
  data: { city, countries },
}) => {
  const fieldsWithData = [
    {
      key: "name",
      label: "Наименование города",
      required: true,
      inputType: inputTypeEnum.text,
    },
    {
      key: "countryID",
      label: "Выберите страну",
      required: true,
      inputType: inputTypeEnum.select,
      options: countries ? countries?.data : []
    },
    {
      key: "iataCode",
      label: "Код аэрапорта в формате IATA",
      inputType: inputTypeEnum.text,
    },
    {
      key: "lat",
      label: "Ширина(lat)",
      inputType: inputTypeEnum.text,
    },
    {
      key: "long",
      label: "Долгота(Ling)",
      inputType: inputTypeEnum.text,
    },
    {
      key: "timeZone",
      label: "TimeZone",
      inputType: inputTypeEnum.text,
    },
    {
      key: "status",
      label: "Статус",
      inputType: inputTypeEnum.select,
      defaultValue: 'active',
      options: [
        {
          id: 'active',
          name: 'Активный'
        },
        {
          id: 'inactive',
          name: 'Неактивный'
        },
      ]
    },
    {
      key: "prayMethod",
      label: "Метод отображения времени намаза",
      inputType: inputTypeEnum.select,
      options: [
        {
          id: 'INSA',
          name: 'INSA'
        },
        {
          id: 'Makkah',
          name: 'Makkah'
        },
        {
          id: 'MWL',
          name: 'MWL'
        },
      ]
    },
  ]

  const onSubmit = async (formData) => {
    try {
      const countryInput = Object.fromEntries(formData)
      if (city) {
        await citiesSAService.put(cityID, countryInput)
        snack("Город обновлен успешно!");
      } else {
        await citiesSAService.post(countryInput)
        snack("Город создан успешно!");
      }

      setTimeout(() => {
        navigate(-1);
      }, 500);
    } catch (ex) {
      console.error(ex)
    }
  };

  return (
    <CrudPage title={city ? 'Редактирование города' : 'Создание города'}>
      <AppForm onSubmit={onSubmit} data={city} fields={fieldsWithData}></AppForm>
    </CrudPage>
  );
};
export default withRouter(
  withRemoteDataAndSpinner(CityForm, (router) => {
    let requests = {
      countries: () => countriesSAService.getAll()
    }
    if (router.params.cityID) {
      requests['city'] = () => citiesSAService.get(router.params.cityID)
    }
    return useLoadAll(requests);
  })
);
