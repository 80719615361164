import { Button, CircularProgress, DialogContent, DialogTitle, IconButton, TextField } from '@mui/material'
import { useRemoteResource } from 'hooks/useRemoteResource'
import { useRef } from 'react'
import { FaSearch } from 'react-icons/fa'
import customersService from 'services/admin/customersService'

export const CustomersDialog = ({ onSelect, customersInput }) => {        
    const [customers, loadCustomers, loading, error] = useRemoteResource(customersService.getAll)
    const searchRef = useRef()

    const onSubmit = () => {
        const query = searchRef.current.value
        console.log(`query:`, query);
        loadCustomers({ query })
    }

    if (error) {
        return <div>{error}</div>
    }

    return (
        <div>
            <DialogTitle>Клиенты</DialogTitle>
            <DialogContent>
                <div className='flex'>
                    <TextField
                        className='w-100'
                        variant='filled'
                        inputRef={searchRef}
                        name="query"
                        label="Поиск клиента по имени/телефону"
                    ></TextField>
                    <IconButton type='button' onClick={onSubmit}>
                        <FaSearch></FaSearch>
                    </IconButton>
                </div>
                {loading ?
                    <div className="text-center"><CircularProgress></CircularProgress></div> :
                    <div className='m-t-32'>
                        {customers.rows.filter(c => {
                            if (customersInput) {                                                                
                                console.log("customersInput:", customersInput);
                                return !customersInput.includes(c.id)
                            }
                            return true
                        }).map(c => {
                            return <div key={c.id} className='m-b-8 flex gap-2 flex-between flex-center'>
                                <div>{c.lastName} {c.firstName}</div>
                                <Button onClick={() => onSelect(c)} type='button'>Выбрать</Button>
                            </div>
                        })}
                    </div>
                }
            </DialogContent>
        </div>
    )
}
