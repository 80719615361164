import { Button, Card, CardContent, CardHeader } from '@mui/material';
import AppTable from 'components/AppTable/AppTable';
import { snack } from 'components/Snack/Snack';
import { TouristList } from 'components/TouristList/TouristList';
import { toDate } from 'helpers/dateHelper';
import { withRemoteDataAndSpinner } from 'hoc/withRemoteDataAndSpinner';
import withRouter from 'hoc/withRouter';
import { useLoadAll } from 'hooks/useLoadAll';
import { useRemoteResource } from 'hooks/useRemoteResource';
import groupTripService from 'services/admin/GroupTripService';
import TouristsService from 'services/admin/touristsService';
import { TripStatus } from 'components/TripStatus/TripStatus';
import { Link } from 'react-router-dom';
import { TourPackagePreview } from 'components/TourPackagePreview/TourPackagePreview';

const memberTableConfig = [
    {
        key: 'name',
        label: 'ФИО'
    },
]


const GroupTripDetails = ({ data: { groupTrip } }) => {
    const [tourists, loadTourists, loading, error] = useRemoteResource(() => TouristsService.getTouristsByTourPackageID(groupTrip.tourPackageID), "");

    async function cancel() {
        try {
            const { data: { message } } = await groupTripService.cancel(groupTrip.id)
            snack(message)
            window.location.reload()
        } catch (ex) {
            console.error(ex)
            snack('Что то произошло во время отмены поездки')
        }
    }
    async function complete() {
        try {
            const { data: { message } } = await groupTripService.complete(groupTrip.id)
            snack(message)
            window.location.reload()
        } catch (ex) {
            console.error(ex)
            snack('Что то произошло во время завершения поездки')
        }
    }

    return (
        <section>
            <Card>
                <CardHeader title="О поездке"></CardHeader>
                <CardContent>
                    <div className='m-b-8'>Дата начало поездки: {toDate(groupTrip.tourPackage?.startDate)}</div>
                    <div className='m-b-8' >Дата конца поездки: {toDate(groupTrip.tourPackage?.endDate)}</div>
                    <TripStatus status={groupTrip.status}></TripStatus>
                    <div className='m-t-8 fs-14 color-gray'>
                        {groupTrip.comment ? <p>Заметка: {groupTrip.comment}</p> : null}
                    </div>
                </CardContent>
            </Card>
            {/* Tourpackage detailed */}
            <TourPackagePreview tourPackage={groupTrip.tourPackage}></TourPackagePreview>

            <Card className='m-t-32'>
                <CardHeader title="Информация о туристах"></CardHeader>
                <CardContent>
                    <TouristList tourists={tourists}></TouristList>
                </CardContent>
            </Card>

            <Card className='m-t-32'>
                <CardHeader title="Информация о гидах"></CardHeader>
                <CardContent>
                    <AppTable data={groupTrip.members || []} columnConfig={memberTableConfig}></AppTable>
                </CardContent>
            </Card>

            {['CREATED'].includes(groupTrip.status) ? <div className='flex gap-8 m-t-32'>
                <div className='flex flex-between w-100'>
                    <div className='flex-start'>
                        <Button variant='contained' onClick={complete}>Завершить поездку</Button>
                        <Button onClick={cancel}>Отменить поездку</Button>
                    </div>
                    <div className='justify-end'>
                        <Link to={`/admin/group-trips/edit/${groupTrip.id}`}>
                            <Button>Редактировать поездку</Button>
                        </Link>
                        <Link to={`/admin/group-trips/edit/${groupTrip.id}`}>
                            <Button>История уведомления</Button>
                        </Link>
                    </div>                    
                </div>
            </div> : null}
        </section>
    )
}


export default withRouter(
    withRemoteDataAndSpinner(GroupTripDetails, (router) => {
        let requests = {
        }
        if (router.params.id) {
            requests['groupTrip'] = () => groupTripService.get(router.params.id)
        }
        return useLoadAll(requests);
    })
);