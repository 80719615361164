import { useSelector } from "react-redux";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import "./admin.scss";
import Breadcrumb from "components/Breadcrumb/Breadcrumb";
import { withUser } from "hoc/withUser";
import UserMenu from "components/UserMenu/UserMenu";
import { getAuthUser } from "redux/selectors/getUser";
import {
  MdOutlinePlayLesson,
  MdOutlineModeComment,
} from "react-icons/md";
import { useEffect, useRef } from "react";
import { IconButton } from "@mui/material";
import { FaBars } from "react-icons/fa";

const SuperAdminLayout = () => {
  // useCheckAuth([roleEnum.admin]);
  const authUser = useSelector(getAuthUser);
  const sidebar = useRef(null);
  const burger = useRef(null);
  const location = useLocation();

  const menu = [
    {
      icon: MdOutlinePlayLesson,
      path: "organizations",
      label: "Организации",
    },
    {
      icon: MdOutlinePlayLesson,
      path: "countries",
      label: "Страны и городы",
    },
    {
      icon: MdOutlinePlayLesson,
      path: "airports",
      label: "Аэропорты",
    },
    {
      icon: MdOutlinePlayLesson,
      path: "users",
      label: "Пользователи",
    },
    {
      icon: MdOutlinePlayLesson,
      path: "duas",
      label: "Список дуа",
    },
  ];


  useEffect(() => {
    document.addEventListener("click", (event) => {
      if (!sidebar.current) {
        return;
      }
      if (!sidebar.current.contains(event.target) && !burger.current.contains(event.target)) {
        sidebar.current.classList.remove("active");
      }
    });
  }, []);

  useEffect(() => {
    sidebar.current.classList.remove("active");
  }, [location]);

  return (
    <div className="page-wrapper" id="main-wrapper">
      <aside className="left-sidebar admin-nav" ref={sidebar}>
        <div className="admin-logo flex items-center">
          Super Admin
        </div>

        <nav className="sidebar-nav">
          {menu.map((item, inx) => {
            return (
              <NavLink key={inx} to={item.path} relative={true} className="sidebar-link">
                <div>
                  {item.icon && <item.icon />}
                  {item.label}
                </div>
                {item.children && (
                  <div className="submenu">
                    {item.children.map((si, siIndex) => {
                      return (
                        <NavLink key={siIndex} to={si.path} relative={true} className="sidebar-link">
                          <div className="flex gap-8">
                            <span>{si.icon && <item.icon />}</span>
                            <span className="m-l-8">{si.label}</span>
                          </div>
                        </NavLink>
                      );
                    })}
                  </div>
                )}
              </NavLink>
            );
          })}
          <hr />
          <a href="https://docs.google.com/document/d/1JtrZNRnJWAdPcpsFSjtiNmvvUQ_aqiy8Adkcp6lhYuo/edit?usp=sharing" className="sidebar-link" target="_blank">
            <div>
              <MdOutlineModeComment />
              <div>Wiki</div>
            </div>
          </a>
        </nav>
      </aside>
      <section className="admin-content">
        <div className="ml-auto w-max flex">
          <UserMenu
            user={authUser}
            setMobileOpen={() => {
              sidebar.current.classList.toggle("active");
            }}
          />
          <IconButton
            ref={burger}
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={() => {
              sidebar.current.classList.toggle("active");
            }}
            sx={{ display: { sm: "none" } }}
          >
            <FaBars></FaBars>
          </IconButton>
        </div>
        <Breadcrumb />
        <Outlet />
      </section>
    </div>
  );
};

export default withUser(SuperAdminLayout);
