import { adminCustomersRoutes } from "./Customers/customers-routes";
import AdminLayout from "./AdminLayout";
import { adminMembersRoutes } from "./Members/members-routes";
import EmptyPage from "components/EmptyPage/EmptyPage";
import OrgForm from "./Orgs/OrgForm";
import Invoices from "./Invoices/pages/Invoices";
import WhatsappConnect from "./Whatsapp/WhatsappConnect";
import { adminStatsRoutes } from "./Stats/stats-router";
import Profile from "./Profile/Profile";
import { adminTourPackagesRoutes } from "./TourPackages/tour-packages-routes";
import { adminBookingRoutes } from "./Bookings/bookings-routes";
import { adminFAQRoutes } from "./FAQ/faq-routes";
import { adminGroupTripsRoutes } from "./GroupTrip/group-trips-routes";
import { adminTourSchedulesRoutes } from "./TourSchedules/tour-schedule-routes";
import { adminLocationsRoutes } from "./Locations/locations-routes";

export const adminRoutes = [
  {
    path: "admin",
    element: AdminLayout,
    name: "",
    children: [
      ...adminCustomersRoutes,      
      {
        element: OrgForm,
        path: "orgs/:id/edit",
      },
      {
        element: Invoices,
        path: "invoices",
      },
      {
        element: EmptyPage,
        path: "comments",
      },
      {
        element: WhatsappConnect,
        path: "whatsapp/connect",
      },
      {
        element: Profile,
        path: "profile",
      },
      ...adminMembersRoutes,
      ...adminTourPackagesRoutes,
      ...adminStatsRoutes,
      ...adminBookingRoutes,
      ...adminFAQRoutes,
      ...adminGroupTripsRoutes,  
      ...adminLocationsRoutes    
    ],
  },
];
