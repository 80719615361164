import AppForm from "components/AppForm/AppForm";
import CrudPage from "components/CrudPage/CrudPage";
import { withRemoteDataAndSpinner } from "hoc/withRemoteDataAndSpinner";
import withRouter from "hoc/withRouter";
import { useLoadAll } from "hooks/useLoadAll";
import inputTypeEnum from "enums/inputTypes";
import { snack } from "components/Snack/Snack";
import tourScheduleService from "services/admin/tourScheduleService";

const TourScheduleForm = ({
  router: {
    navigate,
    params: { id },
  },
  data: { tourSchedule },
}) => {
  const fieldsWithData = [
    {
      key: "photo",
      label: "Основное фото расписания",
      inputType: inputTypeEnum.file,
    },
    {
      key: "name",
      label: "Название расписания тура",
      required: true,
      inputType: inputTypeEnum.text,
    },
    {
      key: "description",
      label: "Описание",
      inputType: inputTypeEnum.richText,
    },
  ]

  const onSubmit = async (formData) => {
    try {
      if (tourSchedule) {
        await tourScheduleService.put(id, formData)
        snack("Расписание обновлен успешно!");
      } else {
        await tourScheduleService.post(formData)
        snack("Расписание создан успешно!");
      }

      setTimeout(() => {
        navigate(-1);
      }, 500);
    } catch (ex) {
      console.error(ex)
    }
  };

  return (
    <CrudPage title={tourSchedule ? 'Редактирование расписания' : 'Создание расписания тура'}>
      <AppForm onSubmit={onSubmit} data={tourSchedule} fields={fieldsWithData}></AppForm>
    </CrudPage>
  );
};
export default withRouter(
  withRemoteDataAndSpinner(TourScheduleForm, (router) => {
    let requests = {}
    if (router.params.tourScheduleID) {
      requests['tourSchedule'] = () => tourScheduleService.get(router.params.tourScheduleID)
    }
    return useLoadAll(requests);
  })
);
