import { Outlet } from "react-router-dom";
import { DuaList } from "./DuaList";
import DuaForm from "./DuaForm";

export const duasRoutes = [
  {
    path: "duas",
    element: Outlet,
    children: [
      {
        path: "",
        element: DuaList,
      },
      {
        path: "add",
        element: DuaForm,
      },
      {
        path: "edit/:id",
        element: DuaForm,
      },
    ],
  },
];
