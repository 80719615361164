import { Outlet } from "react-router-dom";
import TourScheduleList from "./TourScheduleList";
import TourScheduleForm from "./TourScheduleForm";
import TourScheduleView from "./TourScheduleView";
import DailyActivityForm from "./DailyActivityForm";

export const adminTourSchedulesRoutes = [
  {
    path: "tour-schedules",
    element: Outlet,
    children: [
      {
        path: "",
        element: TourScheduleList,
      },
      {
        path: "add",
        element: TourScheduleForm,
      },
      {
        path: "edit/:tourScheduleID",
        element: TourScheduleForm,
      },
      {
        path: "view/:tourScheduleID",
        element: TourScheduleView,
      },
      {
        path: "view/:tourScheduleID/daily-activity/add",
        element: DailyActivityForm,
      },
      {
        path: "view/:tourScheduleID/daily-activity/edit/:id",
        element: DailyActivityForm,
      },      
    ],
  },
];
