import { Button, Card, CardActions, CardContent, CardHeader } from '@mui/material';
import { snack } from 'components/Snack/Snack';
import { Link } from 'react-router-dom';
import appAxios from 'services/axios';

const orgID = localStorage.getItem('orgID')

export const DailyActivities = ({ dailyActivities, tourScheduleID }) => {
    async function deleteDay(daiActivityID) {
        if (window.confirm('Вы точно хотите удалить день?')) {
            try {
                await appAxios.delete(`/admin/orgs/${orgID}/tour-schedules/${tourScheduleID}/daily-activities/${daiActivityID}`)
                snack('День удалился успешно!')
                setTimeout(() => {
                    window.location.reload()
                }, 1500);
            } catch (ex) {
                alert(ex?.message)
            }
        }
    }

    return (
        <Card>
            <CardHeader title="Действия на каждый день"></CardHeader>
            <CardContent>
                {dailyActivities ? dailyActivities.map(a => {
                    return <section className='m-b-32'>
                        <div className='flex gap-16 mb-8'>
                            <div>
                                {a.photo && <img style={{ width: 30 }} src={a.photo} alt='Daily Activity' />}
                            </div>
                            <div>
                                <h4>{a.name}</h4>
                                {a.description ? <p dangerouslySetInnerHTML={{ __html: a.description }}></p> : null}
                            </div>
                        </div>
                        <div className='flex gap-16'>
                            <Link to={`daily-activity/edit/${a.id}`}>
                                <Button size='small'>Редактировать день</Button>
                            </Link>
                            <Button size='small' onClick={() => deleteDay(a.id)}>Удалить день</Button>
                        </div>
                    </section>
                }) : null}
            </CardContent>
            <CardActions>
                <Link to={'daily-activity/add'}>
                    <Button>Добавить новый день</Button>
                </Link>
            </CardActions>
        </Card>
    )
}
