import { TourScheduleCard } from 'components/TourScheduleCard/TourScheduleCard'
import { withRemoteDataAndSpinner } from 'hoc/withRemoteDataAndSpinner';
import withRouter from 'hoc/withRouter';
import { useLoadAll } from 'hooks/useLoadAll';
import tourScheduleService from 'services/admin/tourScheduleService';
import { DailyActivities } from './DailyActivites';

const TourScheduleView = ({ data: { tourSchedule } }) => {
    return (
        <div>
            <TourScheduleCard data={tourSchedule} options={{hideButtons: true}}></TourScheduleCard>
            <section className='m-t-32'>
                <DailyActivities dailyActivities={tourSchedule.dailyActivities}></DailyActivities>
            </section>
        </div>
    )
}

export default withRouter(
    withRemoteDataAndSpinner(TourScheduleView, (router) => {
        let requests = {}
        if (router.params.tourScheduleID) {
            requests['tourSchedule'] = () => tourScheduleService.get(router.params.tourScheduleID)
        }
        return useLoadAll(requests);
    })
);