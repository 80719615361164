import { CircularProgress } from '@mui/material';
import { CURRENCY_SYMBOL } from 'enums/currencySymbols';
import { useRemoteResource } from 'hooks/useRemoteResource';
import { currencyRatesService } from 'services/currencyRates';

const CurrencyRates = () => {
    const [currencyRates, loadCurrencyRates, loading, error] = useRemoteResource(()=>currencyRatesService.getAll())
    console.log(`currencyRates: `, currencyRates);

    if (loading) {
        return <div className="text-center"><CircularProgress></CircularProgress></div>
    }

    if(!currencyRates) return null

    const USD = currencyRates.find(c => c.title === 'USD')
    const EUR = currencyRates.find(c => c.title === 'EUR')

    return (
        <div className='fs-14 no-mobile flex gap-16'>
            <span>USD: {USD.description} {CURRENCY_SYMBOL.KZT}</span>
            <span>EUR: {EUR.description} {CURRENCY_SYMBOL.KZT}</span>
        </div>
    )
}


export default CurrencyRates