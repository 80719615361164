import { Outlet } from "react-router-dom";
import LocationForm from "./LocationForm";
import { LocationList } from "./LocationList";

export const adminLocationsRoutes = [
  {
    path: "locations",
    element: Outlet,
    children: [
      {
        path: "",
        element: LocationList,
      },
      {
        path: "add",
        element: LocationForm,
      },
      {
        path: "edit/:id",
        element: LocationForm,
      },
    ],
  },
];
