import { snack } from "components/Snack/Snack";
import AppForm from "components/AppForm/AppForm";
import CrudPage from "components/CrudPage/CrudPage";
import withRouter from "hoc/withRouter";
import { withRemoteDataAndSpinner } from "hoc/withRemoteDataAndSpinner";
import { useLoadAll } from "hooks/useLoadAll";
import inputTypeEnum from "enums/inputTypes";
import duasSAService from "services/superadmin/duasSAService";

const DuaForm = ({
  router: {
    navigate,
    params: { id },
  },
  data: { dua },
}) => {

  const fieldsWithData = [
    {
      key: "name",
      label: "Название",
      required: true,
      inputType: inputTypeEnum.text,
    },
    {
      key: "description",
      label: "Описание",
      required: true,
      inputType: inputTypeEnum.richText,
    },
    {
      key: "arabicText",
      label: "Арабский текст",
      inputType: inputTypeEnum.richText,
    },
    {
      key: "translation",
      label: "Перевод",
      inputType: inputTypeEnum.richText,
    },
    {
      key: "transcript",
      label: "Транскрипт",
      inputType: inputTypeEnum.richText,
    },
    {
      key: "language",
      label: "Язык",
      inputType: inputTypeEnum.select,
      options: [
        {
          id: 'kz',
          name: 'Казахский язык'
        },
        {
          id: 'ru',
          name: 'Русский язык'
        },
      ]
    },
    {
      key: "status",
      label: "Статус",
      inputType: inputTypeEnum.checkbox      
    },
  ]

  const onSubmit = async (formData) => {
    try {
      const duaInput = Object.fromEntries(formData)
      if (dua) {
        await duasSAService.put(id, duaInput)
        snack("Дуа обновлен успешно!");
      } else {
        await duasSAService.post(duaInput)
        snack("Дуа создан успешно!");
      }

      setTimeout(() => {
        navigate(-1);
      }, 500);
    } catch (ex) {
      console.error(ex)
    }
  };

  return (
    <CrudPage title={dua ? 'Редактирование дуа' : 'Создание дуа'}>
      <AppForm onSubmit={onSubmit} data={dua} fields={fieldsWithData}></AppForm>
    </CrudPage>
  );
};

export default withRouter(
  withRemoteDataAndSpinner(DuaForm, (router) => {
    let requests = {}
    if (router.params.id) {
      requests['dua'] = () => duasSAService.get(router.params.id)
    }
    return useLoadAll(requests);
  })
);
