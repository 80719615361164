import { Alert, CircularProgress } from '@mui/material'
import AppTable from 'components/AppTable/AppTable'
import { expiredInDays } from 'helpers/dateHelper'
import { useRemoteResource } from 'hooks/useRemoteResource'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import customersService from 'services/admin/customersService'

export const ExpiredPassport = () => {
    const columnConfig = [
        {
            label: '#',
            render: (v, index) => `${index + 1}`
        },
        {
            key: 'name',
            label: 'Клиент',
            render: (i) => <Link className='color-blue' to={`/admin/customers/view/${i.id}`}>{i.lastName} {i.firstName}</Link>
        },
        {
            key: 'document',
            label: 'Тип документа',
            render: (i) => <span>Паспорт</span>
        },
        {
            key: 'passport.dateOfExpire',
            label: 'Срок истечения',
            render: (i) => `${i.passport.dateOfExpire} (${expiredInDays(i.passport.dateOfExpire)})`
        },
        {
            key: 'passport.number',
            label: 'Номер паспорта',
        },
    ]

    const [expiredDocuments, loadData, loading, error] = useRemoteResource(() => customersService.getExpiredPassport({}))

    console.log(`unpaidClients:`, expiredDocuments);

    return loading ? <div className="text-center"><CircularProgress></CircularProgress></div> :
        <div>
            <h3 className='m-b-16'>Просроченные документы</h3>
            <Alert className='m-b-32' color='info'>В данном разделе или списке показаны документы, у которых срок действия подходит к концу,
                и их необходимо обновить или продлить в ближайшее время.</Alert>
            <AppTable data={expiredDocuments} columnConfig={columnConfig}></AppTable>
        </div>
}
