import { useRemoteResource } from "hooks/useRemoteResource";
import withRouter from "hoc/withRouter";
import { Button, CircularProgress, IconButton, Pagination, TextField } from "@mui/material";
import ListPage from "components/ListPage/ListPage";
import bookingsService from "services/admin/bookingsService";
import { useEffect, useState } from "react";
import { toDate, toDocumentFormat } from "helpers/dateHelper";
import { toCurrencyFormat } from "helpers/utils";
import AppTable from "components/AppTable/AppTable";
import { Link } from "react-router-dom";
import { BookingStatus } from "components/BookingStatus";
import { MdArticle, MdEdit } from "react-icons/md";
import moment from "moment";
import { urlReplaceState } from "helpers/url";
import { useForm } from "react-hook-form";
import { LIMIT } from "data/consts";

const columnConfig = [
    {
        key: 'id',
        label: '#',
        render: (b) => {
            return <Link className="color-blue" to={`/admin/bookings/view/${b.id}`}>
                {b.id}
            </Link>
        }
    },
    {
        key: 'createdAt',
        label: 'Дата создания',
        formatter: (v) => toDate(v)
    },
    {
        key: '',
        label: 'Турпакет',
        render: (i) => {
            return <Link className="fw-400" target="_blank" to={`/admin/tour-packages/view/${i.tourPackageID}`}>
                {i.tourPackage?.name} <span className="fs-12">({toDocumentFormat(i.tourPackage?.startDate)}, {moment(i.tourPackage.endDate).diff(moment(i.tourPackage.startDate), 'days')} дней)</span>
            </Link>
        }
    },
    {
        key: 'totalPrice',
        label: 'Общая стоимость',
        render: (i) => toCurrencyFormat(i.totalPrice, i.currency)
    },
    {
        key: 'bookingStatusID',
        label: 'Статус',
        render: (i) => <BookingStatus bookingStatus={i.bookingStatus}></BookingStatus>
    },
    {
        key: 'buyer',
        label: 'Покупатель',
        render: (i) => {
            return <Link target="_blank" to={`/admin/customers/view/${i.buyerID}`}>
                {i.buyer?.lastName} {i.buyer?.firstName}
            </Link>
        }
    },
    {
        key: 'customers',
        label: 'Туристы',
        render: (b) => {
            console.log(`b.customers: `, b);

            if (!b.customers) {
                return ""
            }
            if (b.customers?.length > 3) {
                return <span>Больше 3х туристов</span>
            }
            return <div className="gap-8">
                {b.customers?.map((c, inx) => {
                    return inx !== b.customers.length - 1 ? <Link target="_blank" to={`/admin/customers/view/${c.id}`}>
                        {c.lastName} {c.firstName}<span>, </span>
                    </Link> : <Link target="_blank" to={`/admin/customers/view/${c.id}`}>
                        {c.lastName}  {c.firstName}
                    </Link>
                })}
            </div>
        }
    },
    {
        key: 'edit',
        label: '',
        render: (b) => {
            return <div className="flex gap-8">
                <Link to={`/admin/bookings/edit/${b.id}`}>
                    <IconButton size="small">
                        <MdEdit></MdEdit>
                    </IconButton>
                </Link>
            </div>
        }
    },
    {
        key: 'view',
        label: '',
        render: (b) => {
            return <div className="flex gap-8">
                <Link to={`/admin/bookings/view/${b.id}`}>
                    <IconButton size="small">
                        <MdArticle></MdArticle>
                    </IconButton>
                </Link>
            </div>
        }
    },
]

const BookingList = ({ router: { queryParams } }) => {
    const [query, setQuery] = useState({
        page: queryParams.get("page") || 1,
        query: queryParams.get("query") || "",
        id: queryParams.get("id") || "",
    });

    const [bookings, loadData, loading, error] = useRemoteResource(bookingsService.getAll, query);
    const [view, setView] = useState('table')

    const {
        handleSubmit,
        register,
        setValue,
        getValues,
        watch,
        formState: { isValid },
    } = useForm({
        reValidateMode: "onChange",
        mode: "onChange",
        defaultValues: {
            id: ""
        }
    });

    useEffect(() => {
        // Используется для обновления состояние url
        urlReplaceState(null, query);
    }, [query]);

    const updateSearch = (params) => {
        setQuery({
            ...query,
            ...params,
        });
    };

    const onPageChanged = (e, _page) => {
        updateSearch({ page: _page })
    }

    const onSearch = () => {
        updateSearch({ id: getValues('id'), page: 1 })
    }

    function buildContent() {
        if (error) {
            return <div>{error}</div>
        }
        if (loading) {
            return <div className="text-center"><CircularProgress></CircularProgress></div>
        }

        if (view === 'table') {
            // Количество страниц
            const pageCount = Math.ceil(bookings?.count / LIMIT)
            const showPagination = pageCount > 1
            return <div>
                <AppTable data={bookings?.rows} columnConfig={columnConfig}></AppTable>
                <div>
                    {showPagination ? <Pagination className="m-t-32" page={query.page} count={pageCount} onChange={onPageChanged} /> : null}
                </div>
            </div>
        }
    }
    return (
        <ListPage pageTitle="История заказов" pathToAdd={`../add`}>
            <div className="m-t-32">
                <form onSubmit={handleSubmit(onSearch)} className="m-b-32 flex align-center">
                    <TextField
                        type="search"
                        label="Поиск по номеру бронирования"
                        {...register('id')}
                        autoComplete="off"
                        className="m-w-100"
                        style={{ minWidth: 300 }}
                        onChange={(e) => {
                            if (e.target.value === "") {
                                updateSearch({
                                    id: '',
                                    page: 1
                                })
                            }
                        }}
                    ></TextField>
                    <Button type="submit">Поиск</Button>
                </form>
                {buildContent()}
            </div>
            {/* <pre>
                {JSON.stringify(watch())}
            </pre> */}
        </ListPage>

    );
};

export default withRouter(BookingList);
