import { CircularProgress } from '@mui/material';
import AppAdminTable from 'components/AppAdminTable/AppAdminTable';
import ListPage from 'components/ListPage/ListPage';
import Pagination from 'components/Pagination/Pagination';
import { TableColumns } from 'helpers/tableColumns';
import { useRemoteResource } from 'hooks/useRemoteResource';
import locationsService from 'services/admin/locationsService';

const listConfig = [
    {
        key: "id",
        label: "ID"
    },
    {
        key: 'name',
        label: 'Название'
    },    
    {
        key: "lat",
        label: "Ширина",
    },
    {
        key: "long",
        label: "Долгота",
    },
    {
        key: "isActive",
        label: "Статус",
        formatter: (v) => v ? 'Активный' : 'Неактивный'
    },
    TableColumns.createdDate,
]

export const LocationList = () => {
    const [data, loadData, loading] = useRemoteResource(locationsService.getAll, "");

    const onDelete = async (u) => {
        await locationsService.delete(u.id);
        loadData();
    };

    if (loading) {
        return <div className="text-center"><CircularProgress></CircularProgress></div>
    }

    return (
        <ListPage pageTitle="Список локаций">
            <AppAdminTable
                columnConfig={listConfig}
                data={data?.data || []}
                onDelete={onDelete}
                loading={loading}
            />
            {data && <Pagination total={data.total} limit={50} />}
        </ListPage>
    );
}
