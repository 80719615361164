import { CircularProgress } from '@mui/material'
import AppTable from 'components/AppTable/AppTable'
import { toFullDateName } from 'helpers/dateHelper'
import { useRemoteResource } from 'hooks/useRemoteResource'
import { Link } from 'react-router-dom'
import { toCurrencyFormat } from 'helpers/utils'
import _ from 'lodash'
import bookingsService from 'services/admin/bookingsService'
import { BookingStatusLabel } from 'data/bookingStatus'

export const UnpaidClients = () => {
    const columnConfig = [
        {
            label: '#',
            render: (v, index) => `${index + 1}`
        },        
        {
            key: 'bookingID',
            label: 'Номер Заказа',
            render: (i) => <Link className='color-blue' to={`/admin/bookings/view/${i.bookingID}`}>{i.bookingID}</Link>
        },
        {
            key: 'buyerName',
            label: 'Клиент',
            render: (i) => `${i.buyerLastName} ${i.buyerFirstName}`
        },
        {
            key: 'tourPackageEndDate',
            label: 'Крайний срок оплаты',
            formatter: (v) => toFullDateName(v)
        },
        {
            key: 'bookingStatus',
            label: 'Статус заказа',
            render: (i) => <span>{BookingStatusLabel[i.bookingStatus]}</span>
        },
        {
            key: 'totalPrice',
            label: 'Общая сумма заказа',
            render: (i) => toCurrencyFormat(i.totalPrice, 'USD')
        },
        {
            key: 'paid',
            label: 'Оплачено',
            render: (i) => toCurrencyFormat(i.paid, 'USD')
        },
        {
            key: 'duty',
            label: 'Задолжность',
            render: (i) => {
                const duty = Number(i.totalPrice) - Number(i.paid)
                return toCurrencyFormat(duty > 0 ? duty : 0, 'USD')
            }
        },
        {
            key: 'tourPackageName',
            label: 'Турпакет'
        }
    ]

    const [unpaidClients, loadData, loading, error] = useRemoteResource(() => bookingsService.unpaidClients({}))

    console.log(`unpaidClients:`, unpaidClients);


    return loading ? <div className="text-center"><CircularProgress></CircularProgress></div> :
        <div>
            <h3 className='m-b-16'>Графа должников</h3>
            <AppTable data={unpaidClients} columnConfig={columnConfig}></AppTable>
        </div>
}
