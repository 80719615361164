import CrudPage from "components/CrudPage/CrudPage";
import { withRemoteDataAndSpinner } from "hoc/withRemoteDataAndSpinner";
import withRouter from "hoc/withRouter";
import { useLoadAll } from "hooks/useLoadAll";
import { snack } from "components/Snack/Snack";
import { Alert, Autocomplete, Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import groupTripService from "services/admin/GroupTripService";
import { useEffect, useState } from "react";
import tourPackagesService from "services/admin/tour-packages.service";
import { toDate, toFullDateName } from "helpers/dateHelper";
import './groupTripForm.scss'
import _ from "lodash";
import touristsService from "services/admin/touristsService";
import AppTable from "components/AppTable/AppTable";
import membersService from "services/admin/membersService";
import { TripStatus } from "components/TripStatus/TripStatus";
import { BookingStatus } from "components/BookingStatus";



const GroupTripForm = ({
  router: {
    navigate,
    params: { id },
  },
  data: { groupTrip, tourPackages, members },
}) => {
  const { register,
    handleSubmit,
    setValue,
    control,
    getValues,
    formState: { errors, dirtyFields, isDirty, isValid, isSubmitting, isSubmitted },
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      tourPackageID: groupTrip ? groupTrip.tourPackageID : "",
      memberIDs: groupTrip ? groupTrip.members.map(m => m.id) : [],
      comment: groupTrip ? groupTrip.comment : ""
    },
  });

  const [editable, setEditable] = useState(true)
  const [tourists, setTourists] = useState([])
  const [isTouristsLoading, setTouristsLoading] = useState(false)

  const touristColumnConfig = [
    {
      label: '#',
      render: (v, index) => index + 1
    },
    {
      key: 'name',
      label: 'Турист',
      render: (i) => _.get(i, 'customer.lastName') + ' ' + _.get(i, 'customer.firstName')
    },
    {
      key: 'createdAt',
      label: 'Дата приобретения',
      formatter: (v) => toDate(v)
    },
    {
      key: 'bookingStatusID',
      label: 'Статус заказа',
      render: (i) => <BookingStatus bookingStatus={i.booking?.bookingStatus}></BookingStatus>
    }
  ]

  const onSubmit = async (formData) => {
    try {
      if (groupTrip) {
        await groupTripService.put(id, formData)
        snack("Поездка обновлен успешно!");
        navigate(`/admin/group-trips/view/${groupTrip.id}`)
      } else {
        const { data: createdGroupTrip } = await groupTripService.post(formData)
        snack("Поездка создан успешно!");
        navigate(`/admin/group-trips/view/${createdGroupTrip.id}`)
      }
    } catch (ex) {
      console.error(ex)
    }
  };

  const tourPackage = _.find(tourPackages.data, (x) => x.id === getValues('tourPackageID'))

  useEffect(() => {
    console.log('TourPackage is changed to: ', tourPackage);

    const loadTourists = async () => {
      try {
        const { data: _tourists } = await touristsService.getTouristsByTourPackageID(tourPackage.id)
        setTourists(_tourists)
      } catch (ex) {
        console.error(`loadTourists ex: `, ex)
        snack('Error getting tourist')
      }
    }

    if (tourPackage) {
      loadTourists()
    }

  }, [tourPackage])


  return (
    <>
      <CrudPage title={groupTrip ? 'Редактирование поездки' : 'Создание поездки'}>
        <Alert color="info" className="m-b-32">
          <strong>Групповая поездка</strong> — это заранее спланированное путешествие для группы людей с фиксированным маршрутом, стоимостью и сопровождением гида. Чтобы создать групповую поездку:
          <ul>
            <ol>1. Выберите турпакет из списка</ol>
            <ol>2. После выбора турпакета, туристы и дата начала и конца поездки подгрузится автоматически. Проверьте данные туристов которые отправляются по туру</ol>
            <ol>3. При желание добавьте гида или сотрудника, который будет ответственным за поездку</ol>
            <ol>4. Нажмите на кнопку создать поездку</ol>
          </ul>
        </Alert>
        <form className="groupTourForm" onSubmit={handleSubmit(onSubmit)}>
          <Controller control={control} name="tourPackageID" rules={{ required: true }} render={({ field }) => {
            return <FormControl fullWidth>
              <InputLabel>Выберите турпакет</InputLabel>
              <Select disabled={!editable} label="Выберите турпакет" {...field} value={field.value || ''}>
                {tourPackages ?
                  tourPackages.data.map(t => {
                    return <MenuItem key={t.id} value={t.id}>{`${t.name}: (${toFullDateName(t.startDate)} - ${toFullDateName(t.endDate)})`}</MenuItem>
                  })
                  : []}
              </Select>
            </FormControl>
          }}></Controller>
          {/* Дата начало и дата конца поездки автозаполнение */}
          {tourPackage ? <div>
            <strong>Дата начало поездки: </strong>
            <span>{toFullDateName(tourPackage.startDate)}</span>
          </div> : null}

          {tourPackage ? <div>
            <strong>Дата конца поездки: </strong>
            <span>{toFullDateName(tourPackage.endDate)}</span>
          </div> : null}

          {groupTrip ? <TripStatus status={groupTrip.status}></TripStatus> : null}

          {/* Отображать туристов */}
          {tourPackage ? <section id="tourists">
            <h3 className="m-t-32">Информация о туристах</h3>
            {isTouristsLoading ? <div className="text-center"><CircularProgress></CircularProgress></div> :
              tourists ? <AppTable data={tourists} columnConfig={touristColumnConfig}></AppTable> : null}
          </section> : null}

          <div className="m-t-32">
            <Controller
              control={control}
              name="memberIDs"
              rules={{ required: true }}
              render={({ field }) => {
                return <Autocomplete
                  value={field.value ? field.value.map(id => members.data.find(m => m.id === id)) : []}
                  className="m-t-16"
                  multiple
                  options={members.data.map(x => ({ id: x.id, name: x.name }))}
                  getOptionLabel={(o) => o.name}
                  isOptionEqualToValue={(o, v) => {
                    console.log(`E:`, { o, v });

                    return o.id === v.id
                  }}
                  renderInput={(params) => <TextField {...params} label="Выбрать гидов" />}
                  onChange={(e, v) => {
                    console.log(`V:`, v)
                    field.onChange(v.map(x => x.id))
                  }}
                ></Autocomplete>
              }}
            ></Controller>
          </div>

          {tourPackage ? <TextField className="m-t-32 d-block" {...register('comment')} fullWidth label="Заметка"></TextField> : null}
          <Button variant="contained" type="submit" disabled={!isValid}>
            {groupTrip ? <span>Обновить поездку</span> : <span>Создать поездку</span>}
          </Button>
        </form>
        {/* <pre className="m-t-32">
          {JSON.stringify(watch(), null, 4)}
        </pre> */}
        {/* <pre>
          {JSON.stringify({ errors, isValid, isDirty, dirtyFields, isSubmitting, isSubmitted }, null, 4)}
        </pre> */}
      </CrudPage>
    </>
  );
};
export default withRouter(
  withRemoteDataAndSpinner(GroupTripForm, (router) => {
    let requests = {
      tourPackages: () => tourPackagesService.getAll({ limit: 100, isActive: true }),
      members: () => membersService.getAll()
    }
    if (router.params.id) {
      requests['groupTrip'] = () => groupTripService.get(router.params.id)
    }
    return useLoadAll(requests);
  })
);
