import CrudPage from "components/CrudPage/CrudPage";
import { withRemoteDataAndSpinner } from "hoc/withRemoteDataAndSpinner";
import withRouter from "hoc/withRouter";
import { useLoadAll } from "hooks/useLoadAll";
import { snack } from "components/Snack/Snack";
import bookingsService from "services/admin/bookingsService";
import tourPackagesService from "services/admin/tour-packages.service";
import { diffInDays, toDate, toFullDateName } from "helpers/dateHelper";
import { Alert, Button, Card, CardActions, CardContent, CardHeader, Dialog, FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import TourPackageCard from "components/TourPackageCard/TourPackageCard";
import { CustomersDialog } from "components/CustomersDialog/CustomersDialog";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BookingStatus } from "components/BookingStatus";
import { PhoneInput } from "components/PhoneInput/PhoneInput";
import { genders } from "enums/genders";
import { countriesService } from "services/countries.service";
import moment from "moment";
import _ from "lodash";

const BookingForm = ({
  router: {
    navigate,
    params: { id },
  },
  data: {
    booking,
    tourPackages,
    countries
  },
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    getValues,
    formState: { errors, dirtyFields, isDirty, isValid, isSubmitting, isSubmitted, touchedFields, },
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      tourPackageID: booking?.tourPackageID || null,
      customers: booking?.customers ? booking.customers.map(c => {
        return {
          id: c.id,
          lastName: c.lastName,
          firstName: c.firstName,
          gender: c.gender,
          citizenshipID: c.citizenshipID || "KAZ",
          birthdate: c.birthdate,
          user: {
            phone: c.user?.phone
          },
          passport: {
            number: c.passport?.number,
            dateOfExpire: c.passport?.dateOfExpire
          },
          IDCard: {
            iin: c.IDCard?.iin
          },
          CustomerToBooking: {
            amount: c.CustomerToBooking?.amount,
            currency: 'USD',
            comment: c.CustomerToBooking?.comment
          }
        }
      }) : [],
      totalPrice: booking?.totalPrice || 0,
      comment: booking?.comment || "",
      buyerForm: {
        lastName: booking ? booking.buyerForm?.lastName : '',
        firstName: booking ? booking.buyerForm?.firstName : '',
        phoneCountryCode: booking ? booking.buyerForm?.phoneCountryCode : '7',
        phone: booking ? booking.buyerForm?.phone : '',
        iin: booking ? booking.buyerForm?.iin : ''
      }
    },
  });

  const tourPackageInput = watch(`tourPackageID`)
  const customersInput = watch(`customers`, [])
  const [isCustomersDialogVisible, setCDVisible] = useState(false)
  const [isBuyerDialogVisible, setBuyerDialogVisible] = useState(false)
  const [editable, setEditable] = useState(true)

  useEffect(() => {
    if (booking) {
      const edit = ['created', 'partialPayment', 'fullPayment'].includes(booking.bookingStatus.id)
      setEditable(edit)
    }
  }, [])

  const onSubmit = async (formData) => {
    const isConfirmed = window.confirm('Вы точно хотите оформить заказ?')
    if (!isConfirmed) {
      return
    }
    if (!isValid) {
      // Форма не валидная
      return false
    }
    if (isSubmitting) {
      // Данные в формачке отправляются
      return false
    }
    try {
      if (booking) {
        await bookingsService.put(id, formData)
        snack("Заказ обновлен успешно!");
        navigate(`/admin/bookings/view/${id}`)
      } else {
        const { data: createdOrder } = await bookingsService.post(formData)
        console.log(`createdOrder:`, createdOrder);

        snack("Заказ создан успешно!");
        navigate(`/admin/bookings/view/${createdOrder.id}`)
      }
    } catch (ex) {
      console.error(ex)
    }
  };

  function addCustomer(c) {
    c = {
      id: c.id,
      lastName: c.lastName,
      firstName: c.firstName,
      gender: c.gender,
      citizenshipID: _.get(c, 'citizenshipID'),
      birthdate: c.birthdate,
      user: {
        phone: c.user?.phone
      },
      passport: {
        number: c.passport?.number,
        dateOfExpire: c.passport?.dateOfExpire
      },
      IDCard: {
        iin: c.IDCard?.iin
      },
      CustomerToBooking: {
        amount: tourPackages?.data && tourPackageInput ? tourPackages.data.find(t => t.id === tourPackageInput)?.price : '',
        currency: 'USD',
        comment: c.CustomerToBooking?.comment
      }
    }
    const prevValues = getValues('customers') || []
    const prevValuesIDs = prevValues.map(c => c.id)
    if (!prevValuesIDs.includes(c.id)) {
      prevValues.push(c)
    }
    setValue('customers', prevValues, {
      shouldValidate: true
    })
    setCDVisible(false)
    recalculateTotalPrice()
  }
  function addBuyer(b) {
    console.log('Buyer: ', b)
    setValue('buyerID', b.id)
    setValue('buyerForm', {
      lastName: b.lastName,
      firstName: b.firstName,
      phone: b.user.phone,
      phoneCountryCode: b.user.phonePrefix || 7,
      iin: b?.IDCard?.iin
    })

    setBuyerDialogVisible(false)
  }

  function deleteCustomer(customerID) {
    let prevValues = getValues('customers') || []
    prevValues = prevValues.filter(cID => cID !== customerID)
    setValue('customers', prevValues, {
      shouldValidate: true
    })
    recalculateTotalPrice()
  }

  function recalculateTotalPrice() {
    const customers = getValues('customers', [])
    const tourPackageID = getValues('tourPackageID')
    const customersInput = getValues('customers')
    const tourPackage = tourPackages.data.find(t => t.id === tourPackageID)

    if (tourPackage && customers) {
      const totalPrice = customersInput.map(c => Number(c.CustomerToBooking.amount)).reduce((acc, curr) => acc + curr, 0)
      setValue('totalPrice', totalPrice)
    }
  }

  useEffect(() => {
    // Если меняется турпакет пересчитываем
    recalculateTotalPrice()
  }, [tourPackageInput])


  function addNewCustomer() {
    const prevCustomers = getValues('customers') || []

    setValue('customers', [...prevCustomers, {
      "CustomerToBooking": {
        "amount": "",
        "currency": "USD",
        "comment": ""
      },
      citizenshipID: 'KAZ'
    }], {
      shouldTouch: true
    })
  }

  console.log(`BookingForm errors: `, errors);

  function displayErrors(key) {
    // For all required type
    const error = _.get(errors, key)
    if (error) {
      if (error.type === "minLength" || error.type === "maxLength") {
        return <span className="color-red">Неправильная длина поля</span>
      }
      return `Заполните поле`
    }

    return null
  }


  /**
   * Состояние формы
  */
  return (
    <CrudPage title={booking ? 'Редактирование заказа' : 'Создание заказа'}>
      <div className="m-b-32">
        {booking ? null :
          <Alert color="info">Чтобы создать заказ, пожалуйста:
            <ul>
              <ol>1. Выберите турпакет из списка</ol>
              <ol>2. Выберите информацию о покупателе</ol>
              <ol>3. Выберите туристов из списка нажав на кнопку "Добавить туриста"</ol>
              <ol>4. При необходимости вы можете оставить заметку</ol>
              <ol>5. Проверьте данные и нажмите на кнопку создать заказ</ol>
            </ul>
          </Alert>
        }
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller control={control} name="tourPackageID" rules={{ required: true }} render={({ field }) => {
          return <FormControl fullWidth>
            <InputLabel>Выберите турпакет</InputLabel>
            <Select disabled={!editable} label="Выберите турпакет" {...field} value={field.value || ''}>
              {tourPackages ?
                tourPackages.data.map(t => {
                  return <MenuItem key={t.id} value={t.id}>{`${t.name}: (${toFullDateName(t.startDate)} - ${toFullDateName(t.endDate)})`}</MenuItem>
                })
                : []}
            </Select>
          </FormControl>
        }}></Controller>
        <div className="m-t-16">
          {tourPackageInput && tourPackages && <TourPackageCard options={{ hideButtons: true }} tourPackage={tourPackages.data.find(t => t.id === tourPackageInput)}></TourPackageCard>}
        </div>
        {booking ? <div className="m-t-32">
          <div>
            <div className="m-b-16">
              <strong>Дата создания заказа: </strong>
              <span>{booking.createdAt ? toDate(booking.createdAt) : 'Отсутсвует'}</span>
            </div>
            <div className="m-b-16">
              <strong>Дата последнего изменения: </strong>
              <span>{booking.updatedAt ? toDate(booking.updatedAt) : 'Отсутсвует'}</span>
            </div>
            <div className="m-b-16">
              <strong>Заказ оформлен сотрудником: </strong>
              <span>{booking.createdByMember?.name ? <Link className="color-blue" target="_blank" to={`/admin/members/view/${booking.createdByMember.id}`}>{booking.createdByMember?.name}</Link> : 'Отсутсвует'}</span>
            </div>
            <div>
              <strong>Статус заказа: </strong>
              <BookingStatus bookingStatus={booking.bookingStatus}></BookingStatus>
            </div>
            <div className="m-t-16 gap-2 flex">
              <strong>Оплатить до:</strong>
              <span>{toFullDateName(booking.tourPackage.startDate)}</span>
              <strong className='color-green'>(осталось {diffInDays(booking.tourPackage?.startDate, new Date())} дней)</strong>
            </div>
            <div className="m-t-16">
              <strong>Номер заказа: </strong>
              <span>#{booking.id}</span>
            </div>
          </div>
        </div> : <></>}

        <Card className="m-t-64" style={{ display: getValues('tourPackageID') ? 'block' : 'none' }}>
          <CardHeader title="Данные покупателя" subheader="Покупатель не обязательно сам турист"></CardHeader>
          <CardContent>
            <div className="m-t-16">
              <div className="flex gap-8">
                <div className="flex-1">
                  <TextField
                    fullWidth {...register('buyerForm.lastName', { required: true })}
                    label="Фамилия(как указан на паспорте)" className="flex-1"
                    InputLabelProps={{ shrink: true }}
                  ></TextField>
                </div>
                <div className="flex-1">
                  <TextField
                    fullWidth {...register('buyerForm.firstName', { required: true })}
                    label="Имя(как указан на паспорте)" className="flex-1"
                    InputLabelProps={{ shrink: true }}
                  ></TextField>
                </div>
                <div className="flex-1">
                  <Controller
                    control={control}
                    name="buyerForm.phone"
                    rules={{ required: true }}
                    render={({ field, fieldState, formState }) => {
                      return <PhoneInput
                        style={{ width: '100% ' }}
                        phoneNumber={field.value}
                        countryCode={getValues('buyerForm.phoneCountryCode')}
                        onChange={(params) => {
                          if (params.countryCode) {
                            setValue('buyerForm.phoneCountryCode', params.countryCode)
                          }
                          if (params.phone !== undefined) {
                            setValue('buyerForm.phone', params.phone)
                          }
                        }}
                      />
                    }}
                  ></Controller>
                </div>
                <div className="flex-1">
                  <TextField fullWidth {...register('buyerForm.iin', { required: true, minLength: 12, maxLength: 12 })} label="ИИН" className="flex-1" InputLabelProps={{ shrink: true }}></TextField>
                </div>
              </div>
            </div>
          </CardContent>
          <CardActions>
            <div className="m-t-32">
              {editable ? <Button type="button" onClick={() => { setBuyerDialogVisible(true) }}>Добавить покупателя из списка</Button> : null}
            </div>
          </CardActions>
        </Card>

        <section id="booking-customers" style={{ display: getValues('tourPackageID') ? 'block' : 'none' }}>
          <h3 className="m-t-64">Информация о туристах</h3>
          <div className="m-t-16">
            {customersInput?.map((c, inx) => {
              return <Card className="m-b-16">
                <CardContent>
                  <CardHeader subheader={`${inx + 1}-турист`}></CardHeader>
                  <div key={inx} className="flex flex-wrap gap-16">
                    <TextField {...register(`customers[${inx}].lastName`)}
                      required
                      label="Фамилия(как указан на паспорте)"
                      style={{ width: 300 }}
                    ></TextField>
                    <TextField {...register(`customers[${inx}].firstName`)}
                      required
                      label="Имя(как указан на паспорте)"
                      style={{ width: 300 }}
                    ></TextField>
                    <TextField
                      {...register(`customers[${inx}].birthdate`)}
                      required
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      label="Дата рождения"
                      inputProps={{ max: moment().format('YYYY-MM-DD') }}
                    ></TextField>
                    <Controller
                      control={control}
                      name={`customers[${inx}].user.phone`}
                      rules={{ minLength: 11, maxLength: 12 }}
                      render={({ field, fieldState, formState }) => {
                        return <PhoneInput
                          style={{ flex: 1.2 }}
                          phoneNumber={field.value}
                          countryCode={getValues(`customers[${inx}].user.phonePrefix`)}
                          onChange={(params) => {
                            if (params.countryCode) {
                              setValue(`customers[${inx}].user.phonePrefix`, params.countryCode)
                            }
                            if (params.phone !== undefined) {
                              setValue(`customers[${inx}].user.phone`, params.phone)
                            }
                          }}
                        />
                      }}
                    ></Controller>
                    <Controller control={control} name={`customers[${inx}].citizenshipID`} rules={{ required: true }} render={({ field }) => {
                      return <FormControl style={{ width: 200 }}>
                        <InputLabel>Гражданство</InputLabel>
                        <Select label="Гражданство" {...field} value={field.value || ''}>
                          {countries.data.map(c => {
                            return <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>
                          })}
                        </Select>
                      </FormControl>
                    }}></Controller>
                    <TextField {...register(`customers[${inx}].IDCard.iin`, { minLength: 12, maxLength: 12, required: true })} required type="number" label="ИИН" helperText={displayErrors(`customers[${inx}].IDCard.iin`)}></TextField>
                    <TextField {...register(`customers[${inx}].passport.number`, { minLength: 9, maxLength: 9 })} required type="text" InputLabelProps={{ shrink: true }} label="Номер паспорта" helperText={displayErrors(`customers[${inx}].passport.number`)}></TextField>
                    <TextField {...register(`customers[${inx}].passport.dateOfExpire`)} required type="date" InputLabelProps={{ shrink: true }} label="Дата окончания срока действия"></TextField>
                    <Controller control={control} name={`customers[${inx}].gender`} rules={{ required: true }} render={({ field }) => {
                      return <FormControl style={{ width: 200 }}>
                        <InputLabel>Пол</InputLabel>
                        <Select disabled={!editable} label="Пол" {...field} value={field.value || ''}>
                          {genders.map(g => {
                            return <MenuItem key={g.id} value={g.id}>{g.name}</MenuItem>
                          })}
                        </Select>
                      </FormControl>
                    }}></Controller>
                  </div>
                  <hr />
                  <section>
                    <h4 className="m-b-16">Стоимость тура для данного туриста</h4>
                    <div className="flex gap-8">
                      <Controller control={control} name={`customers[${inx}].CustomerToBooking.amount`} render={({ field }) => {
                        return <TextField {...field} onChange={(e) => {
                          field.onChange(e)
                          recalculateTotalPrice()
                        }}></TextField>
                      }}>

                      </Controller>
                      <TextField {...register(`customers[${inx}].CustomerToBooking.currency`)} required disabled label="Валюта" style={{ width: 300 }}></TextField>
                      <TextField {...register(`customers[${inx}].CustomerToBooking.comment`)} type="text" label="Заметка" style={{ width: 300 }}></TextField>
                    </div>
                  </section>
                </CardContent>
                <CardActions>
                  <Button type="button" onClick={() => deleteCustomer(c)}>Удалить</Button>
                </CardActions>
              </Card>
            })}
          </div>
          <div className="m-t-32">
            {editable ? <>
              <Button type="button" onClick={() => { setCDVisible(true) }}>Добавить туриста из списка</Button>
              <Button type="button" onClick={addNewCustomer}>Добавить нового туриста</Button>
            </> : null}
          </div>
        </section>

        <section id="totals" className="field-amout m-t-32" style={{ display: getValues('totalPrice') ? 'block' : 'none' }}>
          <h3 className="m-b-16">Общая сумма к оплате:</h3>
          <TextField {...register('totalPrice')} disabled InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}></TextField>
        </section>
        <section id="comment" className="m-t-16" style={{ display: getValues('totalPrice') ? 'block' : 'none' }}>
          <div htmlFor="comment" className="m-b-8">Заметки:</div>
          <TextField {...register('comment')} id="comment" type="text" multiline rows={2} className="w-100" placeholder="Добавить заметку"></TextField>
        </section>
        <section className="m-t-64 gap-16 flex">
          {booking ? <>
            {editable ?
              <>
                <Button type="submit" variant="contained" disabled={!isValid}>Обновить заказ</Button>
              </>
              : null}
          </> : <>
            <Button type="submit" variant="contained" disabled={isSubmitting}>Создать заказ</Button>
          </>}
        </section>
        {/* <pre className="m-t-32">
          {JSON.stringify(watch(), null, 4)}
        </pre> */}
      </form>
      <Dialog fullWidth maxWidth="md" open={isBuyerDialogVisible} onClose={() => { setBuyerDialogVisible(false) }}>
        <CustomersDialog onSelect={addBuyer}></CustomersDialog>
      </Dialog>
      <Dialog fullWidth maxWidth="md" open={isCustomersDialogVisible} onClose={() => { setCDVisible(false) }}>
        <CustomersDialog onSelect={addCustomer} customersInput={customersInput.map(c => c.id)}></CustomersDialog>
      </Dialog>
    </CrudPage>
  );
};
export default withRouter(
  withRemoteDataAndSpinner(BookingForm, (router) => {
    let requests = {
      tourPackages: () => tourPackagesService.getAll({ limit: 100, status: "true" }),
      countries: () => countriesService.getAll({ limit: 100, status: "true" }),
    }
    if (router.params.id) {
      requests['booking'] = () => bookingsService.get(router.params.id)
    }
    return useLoadAll(requests);
  })
);
