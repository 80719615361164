import AppForm from "components/AppForm/AppForm";
import CrudPage from "components/CrudPage/CrudPage";
import { withRemoteDataAndSpinner } from "hoc/withRemoteDataAndSpinner";
import { addConfig as config } from "tableColumns/org-columns";
import withRouter from "hoc/withRouter";
import { useLoadAll } from "hooks/useLoadAll";
import orgsService from "services/admin/orgsService";
import { useDispatch } from "react-redux";
import { getMeAction } from "redux/actions/authActions";
import { snack } from "components/Snack/Snack";

const OrgForm = ({ router: { navigate }, data: { org } }) => {
  const dispatch = useDispatch();

  const onSubmit = async (formData) => {
    try {
      if (org) {
        // Изменяем организацию
        await orgsService.put(org.id, formData);
        snack('Организация успешно обновлена!')        
        dispatch(getMeAction())
      } else {
        // Добавляем новую организацию
        await orgsService.post(formData);
        snack('Организация успешно добавлена!')
        navigate('/superadmin/organizations')
      }            
    } catch (ex) {
      console.error(`ex:`, ex)
      snack('Вышла ошибка во время обновления организации')
    }
  };

  return (
    <CrudPage title={org ? `Изменение организации` : 'Добавление новой организации'}>
      <AppForm onSubmit={onSubmit} data={org} fields={config} />
    </CrudPage>
  );
};
export default withRouter(
  withRemoteDataAndSpinner(OrgForm, (router) => {
    const requests = {}
    if (router.params.id) {
      requests.org = () => orgsService.get(localStorage.getItem('orgID'))
    }
    return useLoadAll(requests);
  })
);
